import axios from "axios";

export const callApi = async (values) => {
    try {
        const response = await axios.post(`${process.env.REACT_APP_URL_SERVER}/api/resgister`, values);
        return response;
    } catch (error) {
        throw error; 
    }
}

export const saveSession = (key, value) => {
    try {
        sessionStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
        console.error('Error saving to sessionStorage', error);
    }
};

export const getSession = (key) => {
    try {
        const value = sessionStorage.getItem(key);
        return value ? JSON.parse(value) : null;
    } catch (error) {
        console.error('Error reading from sessionStorage', error);
        return null;
    }
};

export const removeSession = (key) => {
    try {
        sessionStorage.removeItem(key);
    } catch (error) {
        console.error('Error removing from sessionStorage', error);
    }
};