import React from 'react'
import PrivacyCenterComponent from '../components/PrivacyCenterComponent'

const PrivacyCenterPage = () => {
  return (
    <div>
      <PrivacyCenterComponent/>
    </div>
  )
}

export default PrivacyCenterPage
